body {
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(33, 29, 29, 1) 100%
  );

  color: white;
}

body,
h1,
h2 {
  padding: 0;
  margin: 0;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  font-family: Nunito;
  color: white;

  // background: rgb(0, 0, 0);
}

.bg-pic {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  img {
    margin: auto;
  }

  .dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
  }
}

.portfolio-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  margin-bottom: 24px;
  margin-top: 24px;

  img {
    width: 64px;
    height: 64px;
  }

  .info {
    margin-left: 12px;

    h1,
    h2 {
      text-transform: uppercase;
    }

    h1 {
      font-size: 26px;
      font-family: "Biryani";
      margin-bottom: -10px;
    }

    h2 {
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 2px;
    }
  }
}

.hero {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;

  .title-wrapper {
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // transform: scale(0.5);
  }

  .logo {
    width: 156px;
    height: 156px;
    margin-bottom: 24px;
  }

  .title,
  .subtitle {
    text-transform: uppercase;
  }

  .title {
    text-transform: uppercase;
    font-family: "Biryani";
    font-size: 24px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 300;
  }
}

.portfolio {
  z-index: 420;
  max-width: 1248px;
  align-self: center;
  overflow: scroll;

  .section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .section-title {
    font-family: "Biryani";
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  .section-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .section-item img {
      width: 100%;
      height: 100%;
    }

    .section-item {
      display: flex;
      margin: 12px;
      flex-direction: column;
      max-width: 350px;

      &.empty {
        visibility: hidden;
      }

      a {
        overflow: hidden;
      }

      .description {
        label {
          display: block;
        }

        .title {
          font-weight: 600;
          font-size: 20px;
          margin-top: 6px;
          margin-bottom: -2px;
        }

        .credits {
          font-weight: 300;
          font-size: 16px;
          margin-top: 6px;
        }
      }

      img {
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
          // opacity: 0.75;
        }
      }
    }
  }
}

.navbar-wrapper {
  border-bottom: 1px solid #d6d9d9;
  padding-bottom: 12px;
  max-width: 420px;
  margin: auto;
  margin-bottom: 32px;
}

.navbar {
  display: flex;
  margin: auto;

  a {
    // font-family: "Biryani";
    color: #d6d9d9;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media (max-width: 600px) {
  .title-wrapper {
    transform: scale(0.9);
  }

  .container {
  }

  .section-item.empty,
  .section-item a {
    width: 100%;
  }

  .portfolio .section-title {
    &:nth-child(1) {
      margin: 56px 0 8px 0;
    }
  }

  .portfolio {
    width: 100%;
  }

  .hero {
    .title {
      font-size: 32px;
      letter-spacing: 4px;
    }

    .subtitle {
      font-size: 20px;
      letter-spacing: 10px;
    }
  }

  .navbar {
    width: 90%;
    justify-content: space-around;

    a {
      width: 72px;
    }

    a:nth-of-type(1) {
      text-align: left;
    }

    a:nth-of-type(2) {
      text-align: center;
    }

    a:nth-of-type(3) {
      text-align: right;
    }
  }
}

@media (min-width: 601px) {
  .title-wrapper {
    transform: scale(0.5);
  }

  .container {
  }

  .section-item.empty,
  .section-item a {
    width: 350px;
    height: 220px;
  }

  .hero {
    .title {
      font-size: 90px;
    }

    .subtitle {
      font-size: 40px;
      letter-spacing: 12px;
      margin-top: 2px;
      margin-left: 20px;
    }
  }

  .section {
    padding: 24px 0;
  }

  .navbar-wrapper {
    margin-top: 42px;
    width: 60%;
  }

  .navbar {
    width: 420px;
    justify-content: space-between;

    a {
      width: 132px;
      font-size: 16px;
    }
  }
}

footer {
  display: flex;
  justify-content: center;
  font-weight: 300;

  .blazeit-c {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 64px 0 16px 0;
    font-size: 12px;
  }
}

.freeze {
  overflow: hidden;
}
